import { Box, Stack, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { primary } from "../../constants/theme";

const CustomCountDownTimer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  ".head-text": {
    fontSize: "40px",
    fontWeight: "600",
    color: primary.mainText,
  },
  ".secondary-text": {
    fontSize: "22px",
    fontWeight: "400",
    color: primary.mainText,
  },
  ".openNow": {
    fontSize: "36px",
    fontWeight: "600",
  },

  [theme.breakpoints.down("md")]: {
    ".head-text": {
      fontSize: "22px",
    },
    ".secondary-text": {
      fontSize: "14px",
    },
    ".openNow": {
      fontSize: "22px",
    },
  },
}));

const CountDownTimer = () => {
  const [remainingTime, setRemainingTime] = useState("0:00:00:00");

  useEffect(() => {
    // Set the start and end dates
    const endDate = new Date("2023-05-26T00:00:00"); // Replace with your desired end date

    // Calculate the remaining time
    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const difference = endDate - currentTime;

      // Check if the end date has been reached
      if (difference <= 0) {
        clearInterval(intervalId);
        setRemainingTime("0:00:00:00");
        return;
      }

      // Calculate remaining hours, minutes, and seconds
      const day = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(difference / (1000 * 60 * 60)) % 24;
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      // Format the remaining time as a string
      const timeString = `${day}:${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      // Update the remaining time in the component state
      setRemainingTime(timeString);
    }, 1000); // Update the timer every second

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  return (
    <CustomCountDownTimer>
      {/* {remainingTime?.split(":")?.length > 1 ? ( */}
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Stack className="openNow" display="flex" flexDirection={"row"}>
          <span style={{ color: primary.electricGreen, paddingLeft: "8px" }}>
            Open Now!!
          </span>
        </Stack>
        {/* <Box
          sx={{
            padding: { xs: "8px 22px 8px 22px", md: "8px 32px 8px 32px" },
            background: primary.white,
            borderRadius: "8px",
            margin: { xs: "4px", sm: "12px" },
            width: { xs: "80px", md: "140px" },
          }}
        >
          <Stack className="head-text">{remainingTime?.split(":")[0]}</Stack>
          <Stack className="secondary-text">Day</Stack>
        </Box>

        <Box
          sx={{
            padding: { xs: "8px 22px 8px 22px", md: "8px 32px 8px 32px" },
            background: primary.white,
            borderRadius: "8px",
            margin: { xs: "4px", sm: "12px" },
            width: { xs: "80px", md: "140px" },
          }}
        >
          <Stack className="head-text">{remainingTime?.split(":")[1]}</Stack>
          <Stack className="secondary-text">Hour</Stack>
        </Box>

        <Box
          sx={{
            padding: { xs: "8px 22px 8px 22px", md: "8px 32px 8px 32px" },
            background: primary.white,
            borderRadius: "8px",
            margin: { xs: "4px", sm: "12px" },
            width: { xs: "80px", md: "140px" },
          }}
        >
          <Stack className="head-text">{remainingTime?.split(":")[2]}</Stack>
          <Stack className="secondary-text">Minute</Stack>
        </Box>

        <Box
          sx={{
            padding: { xs: "8px 22px 8px 22px", md: "8px 32px 8px 32px" },
            background: primary.white,
            borderRadius: "8px",
            margin: { xs: "4px", sm: "12px" },
            width: { xs: "80px", md: "140px" },
          }}
        >
          <Stack className="head-text">{remainingTime?.split(":")[3]}</Stack>
          <Stack className="secondary-text">Second</Stack>
        </Box> */}
      </Box>
      {/* ) : (
        <Stack className="head-text" display="flex" flexDirection={"row"}>
          <span style={{ color: primary.electricGreen, paddingLeft: "8px" }}>
            {remainingTime}
          </span>
        </Stack>
      )} */}
    </CustomCountDownTimer>
  );
};

export default CountDownTimer;
