export const META_TITLE = "Buy load Euler electric vehicle at best price";

export const META_CONTENT = [
  {
    name: "description",
    content:
      "Discover load EVs from Euler motor. Check Euler Hiload EV price, features and specifications. Get a free test drive. Get best deals only at Turno. Click now",
  },
  {
    name: "keywords",
    content:
      "euler electric, euler ev, euler motors, euler hiload ev, euler electric vehicle, euler electric auto, Euler tempo, euler hiload ev price, euler three wheeler, euler ev motors, euler ev loader, euler ev auto,euler ev hiload, euler ev tempo price,  euler price in delhi, euler hiload ev showroom near me, Battery rickshaw, euler Cargo, goods vehicle",
  },
  {
    name: "author",
    content: "Turno",
  },
  {
    name: "url",
    content: "https://www.turno.club/electric-commercial-vehicles/",
  },
  {
    name: "robots",
    content: "INDEX,ARCHIVE,FOLLOW",
  },
  {
    name: "lang",
    content: "en",
  },
  {
    name: "viewport",
    content: "width=device-width, initial-scale=1",
  },
];

export const TOP_BANNER_HEADER =
  "Higher Load Capacity, Extended Range: Switch to Euler Motors";

export const BOOK_EV_CONSULTATION_FORM_HEADER =
  "Buy Euler Three Wheeler from Turno";

export const BOOK_EV_CONSULTATION_CTA_TEXT = "Talk to Turno Expert";

export const VEHICLE_LISTING_HEADER_INFO =
  "Euler Electric Vehicles: Three variants to choose from";

export const VEHICLE_LISTING_DESCRIPTION =
  "Join the electric revolution in India with Euler Electric Vehicles. They’re convenient, efficient, and charge almost as fast as your phone. You can try the HiLoad DV or the HiLoad PV: both powerful Euler electric three wheelers at affordable prices. These Euler vehicles come with 30% wider tyres, bigger batteries, and higher payload capacities. So, switch to electric and get ready to carry all day.";

export const EV_PRICES_HEADER = "Euler Hiload EV Price";

export const EV_PRICES_DESCRIPTION =
  "Now carry and deliver more efficiently with our Euler electric auto models. Take a look at the Euler motors price list below, and pick your Euler electric auto according to your business requirements and budget.";

export const PROMISE_HEADER_INFO =
  "Turno: The best place to buy Euler Hiload EV";

export const PROMISE_SUB_HEADER_TEXT = "Get assistance at every step";

export const ADVANTAGES_OF_3_WHEELER_HEADER_INFO =
  "Advantages of Euler Electric 3 Wheelers:";

export const ADVANTAGES_OF_3_WHEELER_CONTENT =
  "We at Turno, are very proud of our journey with the Euler electric 3 Wheelers. Choose Euler auto for their advanced features and superior safety standards. They come with:";

export const VEHICLE_LOAN_HEADER_INFO =
  "Guaranteed loan for Euler electric vehicle";

export const VEHICLE_HIGHLIGHT_HEADER =
  "Highlights of Euler Electric Three Wheeler:";

export const VEHICLE_HIGHLIGHT_TEXT =
  "Euler EV loader auto is an efficient and pocket-friendly three-wheeler with an advanced battery that can be charged for 50 km in 15 minutes. It features a high payload capacity, a liquid-cooled battery, remote vehicle tracking, and a superior safety design that keeps you going all day, every day. Euler tempos are also easy to operate with comfortable seats and ample cargo storage space.";

export const BLOG_HEADER = "Know more about Euler electric vehicles:";

export const ABOUT_TURNO_HEADER_INFO = "About Euler Electric Auto";

export const ABOUT_TURNO_CONTENT =
  "Euler Motors offers a wide range of electric vehicles, including commercial three-wheelers. Euler electric vehicles are designed to be convenient, safe, and affordable - well-suited for use in urban and rural areas. Euler has a strong focus on power and reliability, and the company is constantly developing new ways to improve the efficiency of its vehicles. You can track the Euler EV vehicle in real-time with the Turno App.";

export const VEHICLE_VARIENT_HEADER_INFO = "Vehicle Models by Euler Motors";

export const VEHICLE_VARIANT_DESCRIPTION =
  "Euler Motors presents a range of vehicles that are designed to meet the needs of different users. Here are the two Euler EV vehicles:";

export const EULAR_MAP_LOCATION_HEADER = "Euler Hiload EV Showroom Near Me";

export const EULAR_MAP_LOCATION_DESCRIPTION =
  "With a growing number of people embracing Euler's top-notch mobility solutions that offer unmatched value for money, Turno presents an exceptional range of Euler Electric auto/ Electric tempo at budget-friendly prices. Don't wait any longer – secure your Euler tempo with Turno today and drive towards exceptional savings and a more efficient future.";

export const AVAILABLE_BRANDS_HEADER = "Other Popular Brands Available At Turno";

export const GAURANTEED_LOAN_EMI_CARD_DATA = {
  cardHeader: "Turno provides you lowest interest rate in the market",
  cardSubHeader: "*T&C applied",
  downPayment: {
    value: "₹49,999",
    title: "Down Payment",
  },
  interestRate: {
    value: "10.5%",
    title: "Interest rate",
    subTitle: "",
  },
  emi: {
    value: "36/48",
    title: "Months EMI",
  },
  checkEmiButtonText: "CHECK EMI",
  skipFinancePage: true,
};
