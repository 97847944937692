import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { primary } from "../../../constants/theme";
import { vehicles, newVehicles } from "../../../constants/vehicleData";
import { sendAnalytics } from "../../../utils/analyticsCommon";

import TurnoListCard from "./TurnoListCard";

const CustomHomePageVehicleList = styled(Box)(({ theme }) => ({
  margin: "10px",
  ".headerInfo": {
    fontSize: "27px",
    fontWeight: "600",
  },

  ".subInfo": {
    fontSize: "12px",
    color: primary.greyText,
    fontWeight: 600,
    textTransform: "uppercase",
  },

  [theme.breakpoints.down("md")]: {},
}));

const HomePageVehicleList = () => {
  const targetRef = useRef(null);
  const [allVehicles, setAllVehicles] = useState([]);

  useEffect(() => {
    setAllVehicles((prevAllVehicles) => {
      const skipVehicles = ["Touro-MAX", "Treo-Zor", "Rage-+-8.5-kwh"];
      const keepVehicles = ["Zor-Grand", "HiLoadDV"];
      const filteredVehicles = vehicles?.filter(
        (vehicle) => !skipVehicles?.includes(vehicle?.vehicle_path_id)
      );
      const zorInfo = newVehicles?.filter(
        (vehicle) => keepVehicles?.includes(vehicle?.vehicle_path_id)
      );
      return [...zorInfo, ...filteredVehicles];
    });
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        sendAnalytics("v2_scroll_top_selling_ev", {}, "en");
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef]);
  return (
    <CustomHomePageVehicleList>
      <Box id="top-selling-ev" ref={targetRef}>
        <Stack
          sx={{
            margin: { xs: "32px 16px 16px 16px", sm: "16px 0px 16px 32px" },
          }}
        >
          <Typography flexDirection={"column"} className="headerInfo">
            Top selling EVs
          </Typography>

          <Typography flexDirection={"column"} className="subInfo">
            BY TURNO
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          {allVehicles?.map((data, index) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={3}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                //columnSpacing={1}
              >
                <TurnoListCard vehicleData={data} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </CustomHomePageVehicleList>
  );
};

export default HomePageVehicleList;
