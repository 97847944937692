import { Box, styled } from "@mui/material";
import React from "react";
import { primary } from "../../../constants/theme";

const CustomVehicleInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingBottom: "10px",
  //   alignItems: "center",
  //   flexDirection: "column",
  //   gap: "16px",
  //   marginTop: "48px",
  //   paddingTop: "12px",
  ".content-container": {
    width: "84%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    ".content-heading": {
      fontSize: "50px",
      fontWeight: "600",
      marginBottom: "10px",
    },
    ".content-subheading": {
      fontSize: "30px",
      color: primary.greyText,
      fontWeight: "600",
      marginBottom: "20px",
    },
    ".content-text": {
      fontSize: "16px",
      marginBottom: "10px",
      fontWeight: "400",
      color: primary.black,
    },
    ".content-text.readmoreText": {
      maxHeight: "70px",
      overflow: "hidden",
    },
    ".content-text.readmoreText.expanded": {
      maxHeight: "none",
    },
    ".listItem": {
      fontSize: "16px",
      fontWeight: "400",
      color: primary.black,
      listStyle: "circle",
    },
    ".list-head": {
      fontWeight: "600",
    },
    ".content-heading2": {
      fontSize: "24px",
      fontWeight: "600",
      marginBottom: "10px",
    },
    ".content-subheading2": {
      fontSize: "20px",
      fontWeight: "600",
      color: primary.greyText,
      marginBottom: "20px",
    },
  },
  ".pricing-table": {
    textAlign: "left",
    padding: "8px",
    margin: "auto",
    width: "100%",
  },
  ".readmore-btn": {
    background: primary.white,
    boxShadow: "none",
    border: `2px solid ${primary.main}`,
    borderRadius: "5px",
    padding: "12px",
    marginRight: "20px",
    fontSize: "12px",
    color: primary.main,
    marginBottom: "20px",
  },

  [theme.breakpoints.down("md")]: {
    // marginTop: "24px",
    ".content-container": {
      width: "100%",
      padding: "0 15px",
      ".content-heading": {
        fontSize: "30px",
      },
      ".content-subheading": {
        fontSize: "20px",
      },
      ".content-text": {
        fontSize: "16px",
      },
      ".content-heading2": {
        fontSize: "24px",
      },
      ".content-subheading2": {
        fontSize: "20px",
      },
    },
  },

  [theme.breakpoints.down("sm")]: {
    //padding: "4px",
    ".content-container": {
      width: "100%",
      padding: "0 15px",
      ".content-heading": {
        fontSize: "24px",
        marginBottom: "10px",
      },
      ".content-subheading": {
        fontSize: "16px",
        marginBottom: "20px",
      },
      ".content-text": {
        fontSize: "12px",
      },
      ".content-subheading2": {
        fontSize: "16px",
        marginBottom: "20px",
      },
    },
    ".pricing-table": {
      textAlign: "left",
      padding: "8px",
      margin: "auto",
      width: "100%",
    },
  },
}));
const VehicleInfoList = ({ headerTitle, detailTitle, infoData }) => {
  return (
    <CustomVehicleInfo>
      {/* <Box className="content-container">
        <Typography variant="h2" className="content-heading2">
          {priceData?.headerInfo}
        </Typography>
        <Typography variant="p" className="content-text">
          {priceData?.description ??
            `At Turno we provide you with the best prices on electric commercial
          vehicles. We are committed to providing affordable financing options
          for goods electric vehicles to individuals as well as small and
          mid-sized businesses. With a team of EV experts, Turno leads the
          industry of electric goods auto in India.`}
        </Typography>
      </Box> */}
      {/* Pricing table */}
      <table
        className="pricing-table"
        style={{ border: `1px solid ${primary.greyText}` }}
      >
        {/* <tr
          style={{
            border: `1px solid ${primary.greyText}`,
            textAlign: "left",
            padding: "8px",
          }}
        >
          <th
            style={{
              border: `1px solid ${primary.greyText}`,
              textAlign: "left",
              padding: "8px",
            }}
          >
           {headerTitle??""}
          </th>
          <th
            style={{
              border: `1px solid ${primary.greyText}`,
              textAlign: "left",
              padding: "8px",
            }}
          >
             {detailTitle??""}
          </th>
        </tr> */}
        {infoData?.map((data, index) => (
          <tr
            key={index}
            style={{
              border: "none", //`1px solid ${primary.greyText}`,
              textAlign: "left",
              padding: "8px",
            }}
          >
            <td
              style={{
                border: "none", //`1px solid ${primary.greyText}`,
                textAlign: "left",
                padding: "8px",
              }}
            >
              {data?.feature}
            </td>
            <td
              style={{
                border: "none", //`1px solid ${primary.greyText}`,
                textAlign: "left",
                padding: "8px",
              }}
            >
              {data?.details}
            </td>
          </tr>
        ))}
      </table>
    </CustomVehicleInfo>
  );
};

const MemoizedVehicleInfoList = React.memo(VehicleInfoList);
export default MemoizedVehicleInfoList;
