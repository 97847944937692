import { Box, styled, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { primary } from "../../../constants/theme";
import { newVehicles, vehicles } from "../../../constants/vehicleData";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import { useMobile } from "../../../utils/findViewport";
import ModelCard from "./modelCard";
const CustomLatestModel = styled(Box)(({ theme }) => ({
  //   background: "#FFF9FA",
  ".main-box": {
    margin: "10px",
    marginBottom: "20px",
  },
  ".head-text": {
    padding: "0px 10px",
    fontWeight: 600,
    fontSize: "27px",
    marginTop: "30px",
    paddingLeft: "16px",
  },
  ".sub-text": {
    fontSize: "12px",
    color: "#9C9A9D",
    fontWeight: "600",
    padding: "0px 10px",
    paddingLeft: "16px",
  },
  ".image-box": {
    width: "100%",
    padding: "20px 0px",
  },
  ".test-drive-image": {
    width: "100%",
  },
  ".book-test-drive-button": {
    borderRadius: "8px",
    boxShadow: "none",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  ".MuiOutlinedInput-root": {
    paddingRight: "2px !important",
  },
  ".termsconditions": {
    fontSize: "10px",
  },
  //   ".form-box": {
  //     width: "100%",
  //   },
}));

const LatestModel = ({
  headerInfo,
  view,
  isHeader = true,
  hideButton = false,
  vehicleData,
  isHomePage = false,
  regionName = ""
}) => {
  let allVehicles = [...vehicles, ...newVehicles];
  const targetRef = useRef(null);
  const [count, setCount] = useState(0);
  const mobile = useMobile();
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        sendAnalytics("v2_scroll_latest_model", {}, "en");
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef]);

  return (
    <CustomLatestModel>
      <Box className="main-box" id="latest-model" ref={targetRef}>
        <Box style={{ display: isHeader ? "block" : "none" }}>
          <Typography className="head-text">
            {headerInfo ?? "Latest Model"}
            {regionName ? <span style={{color: "red", textDecoration: "underline"}}> {regionName}</span> : ""}
          </Typography>
        </Box>
        {!isHomePage && <Box style={{ display: isHeader ? "block" : "none", fontSize: "20px" }}>
          <Typography className="sub-text">BY TURNO</Typography>
        </Box>}

        <Box>
          <Swiper
            slidesPerView={mobile ? view ?? 2.2 : 4.2}
            spaceBetween={0}
            centeredSlides={false}
            pagination={false}
            loop={true}
            modules={[Pagination]}
            className="swiper-turno-india"
            onSlideChange={() => {
              let newCount = count + 1;
              setCount(newCount);
              if (newCount > 1)
                sendAnalytics("v2_latest_model_slide", {}, "en");
            }}
          >
            {(vehicleData ?? allVehicles)?.map((data, index) => {
              if(data?.brand !=="OSM" && data?.brand !=="E-Trio" && data?.brand !=="Etrio"){
              return (
                <SwiperSlide key={index}>
                  <ModelCard data={data} hideButton={hideButton} />
                </SwiperSlide>
              );
              }
            })}
          </Swiper>

          {!isHomePage && <Typography
            style={{
              fontSize: "14px",
              // fontWeight: "700",
              lineHeight: "30px",

              margin: "10px",
            }}
          >
            Powered by{" "}
            <span style={{ color: primary?.main }}>
              <LazyLoadImage
                style={{ maxHeight: "14px" }}
                src={"/logoIcon.png"}
                visibleByDefault={true}
                alt="turno-logo-icon"
              />
            </span>
          </Typography>}
        </Box>
      </Box>
    </CustomLatestModel>
  );
};

export default LatestModel;
