import { Typography } from "@material-ui/core";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import ElectricRickshawIcon from "@mui/icons-material/ElectricRickshaw";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import { Box, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { primary } from "../../constants/theme";
import { sendAnalytics } from "../../utils/analyticsCommon";
import TurnoPromises from "./turnoPromises";

import LoopIcon from "@mui/icons-material/Loop";
import PaymentIcon from "@mui/icons-material/Payment";

const CustomTurnoPromiseComponent = styled(Box)(({ theme, backgroundcol }) => ({
  padding: "60px",
  backgroundColor: backgroundcol ?? primary?.lightGrey,
  width: "100%",

  marginBlock: "0px",

  "#home-page-promises": {
    display: "flex",
    gap: "2rem",
  },
  ".promises-container": {
    justifyContent: "flex-end",
    width: "100%",
  },
  ".promises-heading": {
    justifyContent: "flex-start",
  },

  ".heading-info": {
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
    color: primary.dark,
    marginBottom: "10px",
  },

  [theme.breakpoints.down("md")]: {
    padding: "20px",
    borderRadius: "10px",
    "#home-page-promises": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },

    ".heading-info": {
      fontSize: "27px",
      lineHeight: "30px",
      marginBottom: "8px",
    },
  },
}));

const promiseList = [
  {
    id: 1,
    icon: <ElectricRickshawIcon />,
    question: "Wide range of electric cargo",
    answer:
      "We offer a wide range of electric cargo vehicles from leading manufacturers, including Mahindra, Piaggio, E-trio, Euler, Greaves, Altigreen and OSM.",
  },

  {
    id: 2,
    icon: <GroupIcon />,
    question: "Team of EV experts",
    answer:
      "We own a team of experts who can help businesses and individuals choose the right electric commercial vehicle for their needs.",
  },

  {
    id: 3,
    icon: <WorkspacePremiumRoundedIcon />,
    question: "Best financing options",
    answer:
      "We offer financing options to make it easy to purchase electric goods vehicles.",
  },

  {
    id: 4,
    icon: <BuildCircleIcon />,
    question: "Maintenance and repair service",
    answer:
      "We provide maintenance and repair services to keep electric load vehicles running smoothly.",
  },
  {
    id: 5,
    icon: <ManageAccountsIcon />,
    question: "Free doorstep test drives",
    answer:
      "We provide free doorstep test drives for the vehicle of choice so that an informed decision can be taken.",
  },

  {
    id: 6,
    icon: <PaymentIcon />,
    question: "Lowest Down Payment",
    answer:
      "Get hassle-free vehicle delivery with low and flexible down payment options.",
  },

  {
    id: 7,
    icon: <LoopIcon />,
    question: "Assured Resale",
    answer:
      "Experience the added benefit of Turno's assured resale value of up to 40% or ₹ 150,000 for EV auto.",
  },
];
const TurnoPromiseComponent = ({
  promiseData,
  headerInfo,
  tableView,
  plusIcons,
  backgroundCol,
}) => {
  const [view, setView] = useState(0);

  const targetRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        sendAnalytics("v2_scroll_home_page_promises", {}, "en");
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef]);

  return (
    <CustomTurnoPromiseComponent backgroundcol={backgroundCol}>
      <Box id="home-page-promises" ref={targetRef}>
        <Box className="promises-heading">
          <Typography
            variant="h2"
            className="heading-info"
            style={{
              fontSize: headerInfo ? "16px" : "27px",
              textAlign: headerInfo ? "center" : "initial",
            }}
          >
            {headerInfo ?? "Promises"}
          </Typography>{" "}
        </Box>
        <Box className="promises-container">
          {(promiseData ?? promiseList)?.map((faq, id) => {
            return (
              <TurnoPromises
                showItems={1}
                TurnoPromiseComponentCounts={1}
                key={id}
                question={faq.question}
                answer={faq.answer}
                id={id}
                view={view}
                setView={setView}
                icon={faq?.icon}
                tableData={faq?.tableData ?? []}
                tableView={tableView}
                plusIcons={plusIcons}
              />
            );
          })}
        </Box>
      </Box>
    </CustomTurnoPromiseComponent>
  );
};

export default TurnoPromiseComponent;
