import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Grid, Typography, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { vehicleInfo, vehicles } from "../../../constants/vehicleData";
import { Context } from "../../../context/context";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import { createCookieInHour } from "../../../utils/cookie";
import { leadActions } from "../../../utils/leadActions";

const CustomKnowledgeCards = styled(Box)(({ theme }) => ({
  margin: "10px",
}));

const KnowLedgeCards = () => {
  const state = useContext(Context);
  const {
    showCarouselPopup,
    showWhatsappPopup,
    changeVehicleUuid,
    showFormPopup,
  } = useContext(Context);
  const [vehicleData, setVehicleData] = useState([]);
  useEffect(() => {
    if (state?.vehicleBrand) {
      let vehicleDataLocal = vehicles.filter(function (vehicle) {
        return vehicle.id === vehicleInfo[state?.vehicleBrand]?.vehicleId;
      });
      changeVehicleUuid(vehicleDataLocal[0]?.uuid);
      setVehicleData(vehicleDataLocal[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.vehicleBrand]);
  const cardDetails = [
    // {
    //   id: 1,
    //   title: "Electric Vehicle loan",
    //   bodyText: "Looking for vehicle loan?",
    //   clickText: "Apply Now",
    //   iconName: <CurrencyRupeeIcon style={{ fontSize: "20px" }} />,
    //   drawer: showCarouselPopup,
    //   drawerService: "",
    //   eventName: "loan_know_more_card_clicked",
    //   triggerName: "apply_loan_btn",
    //   leadActionName: "CLICK_VEHICLE_LOAN",
    // },
    // {
    //   id: 2,
    //   title: "Book Test Drive",
    //   bodyText: "FREE Test Drive with Turno",
    //   clickText: "Book Now",
    //   iconName: <CarRentalOutlinedIcon style={{ fontSize: "20px" }} />,
    //   drawer: showFormPopup,
    //   drawerService: "Book Test Drive",
    //   eventName: "book_test_drive_know_more_card_clicked",
    //   triggerName: "book_test_drive",
    //   leadActionName: "CLICK_BOOK_TEST_DRIVE",
    // },
    // {
    //   id: 3,
    //   title: "Book Vehicle",
    //   bodyText: "Lowest Interest rate, low processing Fee & Pre Closure Fee",
    //   iconName: <ElectricCarOutlinedIcon style={{ fontSize: "20px" }} />,
    //   drawer: showFormPopup,
    //   drawerService: "Book Vehicle",
    //   eventName: "book_vehicle_know_more_card_clicked",
    //   triggerName: "book_vehicle",
    //   leadActionName: "CLICK_BOOK_VEHICLE",
    // },
    {
      id: 4,
      title: "Chat With Us",
      bodyText: "Contact us for any Queries, Service Requests",
      iconName: <HeadsetMicIcon style={{ fontSize: "20px" }} />,
      drawer: showWhatsappPopup,
      drawerService: "",
      eventName: "chat_us_know_more_card_clicked",
      triggerName: "whatsapp_chat",
      leadActionName: "CLICK_WHATSAPP_CHAT",
    },
  ];
  return (
    <CustomKnowledgeCards>
      <Box style={{ margin: "10px" }} id="know_more_cards">
        {cardDetails.map((card, index) => {
          return (
            <Box
              key={index}
              onClick={() => {
                leadActions(card?.leadActionName);
                card?.drawer(card?.drawerService);
                let payload = {
                  vehicle_name: vehicleData?.name,
                  vehicle_id: vehicleData?.id,
                };
                sendAnalytics(card?.eventName, payload, "en");
                createCookieInHour("lead_trigger", card?.triggerName, 10);
              }}
              style={{
                margin: "20px 0px",
                padding: "25px 10px",
                border: "1px solid #E5E5E5",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                background: "#EFF8F0",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Box
                    style={{
                      width: "35px",
                      height: "35px",
                      background: "#A6D6B8",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Box
                      style={{
                        width: "25px",
                        height: "25px",
                        background: "#35BF68",
                        borderRadius: "50%",
                        color: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "2px",
                      }}
                    >
                      {card?.iconName}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box>
                    {" "}
                    <Box>
                      <Typography
                        style={{ fontSize: "16px", fontWeight: "700" }}
                      >
                        {card?.title}
                      </Typography>
                    </Box>{" "}
                    <Box>
                      <Typography
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        {card?.bodyText}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {card?.clickText}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  {" "}
                  <Box>
                    <KeyboardArrowRightIcon />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
    </CustomKnowledgeCards>
  );
};

export default KnowLedgeCards;
