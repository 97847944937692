import RemoveIcon from "@mui/icons-material/Remove";
import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { primary } from "../../constants/theme";
import MemoizedVehicleInfoList from "../../modules/Vehicles/TableData/VehicleInfo";
import { sendAnalytics } from "../../utils/analyticsCommon";

import AddIcon from "@mui/icons-material/Add";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const CustomTurnoPromises = styled(Box)(({ theme }) => ({
  background: primary?.white,
  padding: "10px",
  paddingBottom: "0px",
  borderRadius: "8px",
  marginBottom: "20px",
  ".faq": {
    ".question-main": {
      display: "flex",
      gap: "11px",
      width: "100%",
      marginBottom: "8px",
      cursor: "pointer",
      svg: {
        width: "30px",
        height: "30px",
        padding: "2px",
      },
      ".question": {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "22px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "32xpx",
        color: primary.black,
        width: "88%",
        justifyContent: "flex-start",
      },
      ".arrow-faq": {
        width: "5%",
        cursor: "pointer",
        color: primary.main,
        justifyContent: "flex-end",
      },

      ".plus-faq": {
        width: "5%",
        cursor: "pointer",
        // color: primary.main,
        justifyContent: "flex-end",
      },
    },
    ".answer": {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "30px",
      color: primary.greyText,
      width: "100%",
      paddingBottom: "10px",
    },
  },
  ".borderDivider": {
    backgroundColor: primary.white,
    width: "85%",
    margin: "24px 0px",
  },
  [theme.breakpoints.down("md")]: {
    ".faq": {
      ".question-main": {
        ".question": {
          fontSize: "18px",
          lineHeight: "24px",
        },
        svg: {
          width: "33px",
          height: "33px",
          fontWeight: "normal",
        },
        ".sound": {
          width: "33px",
          height: "30px",
        },
      },
      ".answer": {
        fontSize: "14px",
        lineHeight: "20px",
        color: primary.greyText,
      },
    },
  },
}));

const TurnoPromises = ({
  question,
  answer,
  id,
  view,
  setView,
  icon,
  tableData,
  plusIcons,
}) => {
  return (
    <CustomTurnoPromises>
      <Box
        className={`faq`}
        id={id}
        onClick={() => {
          let payload = {
            question_id: id + 1,
            question_index: id + 1,
          };
          sendAnalytics(
            "home_promises_more_detail_button_clicked",
            payload,
            "en"
          );
          if (view === id) {
            setView(-1);
          } else {
            setView(id);
          }
        }}
      >
        <Box className="question-main">
          <Box className="question">
            <span style={{ marginRight: "12px", fontWeight: "100" }}>
              {icon}
            </span>
            {`${question}`}
          </Box>
          {/* <Box className="arrow-faq">
            {view !== id ? <ExpandCircleDownIcon /> : <ExpandLessIcon />}
          </Box> */}

          {plusIcons ? (
            <Box className="plus-faq">
              {view !== id ? <AddIcon /> : <RemoveIcon />}
            </Box>
          ) : (
            <Box className="arrow-faq">
              {view !== id ? <ExpandCircleDownIcon /> : <ExpandLessIcon />}
            </Box>
          )}
        </Box>
        {view === id && (
          <>
            {answer?.length > 0 && (
              <Typography className="answer">{`${answer ?? ""}`}</Typography>
            )}
            {tableData?.length > 0 && (
              <MemoizedVehicleInfoList infoData={tableData} />
            )}{" "}
          </>
        )}
      </Box>
    </CustomTurnoPromises>
  );
};

export default TurnoPromises;
