import { Box } from "@mui/material";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { primary } from "../../../constants/theme";

const VehicleBadges = ({ styles, ...props }) => {
  return (
    <Box
      style={{
        // boxShadow:
        //   props?.type === props?.selectedType
        //     ? `4px 4px 4px 4px rgba(232, 52, 94, 0.2)`
        //     : ``,
        border:
          props?.type === props?.selectedType
            ? `2px solid ${primary.main}`
            : "none",

        borderRadius: "50%",

        width: "79px",
        height: "79px",
        // border: "2px solid red",
        margin: "7px",
        ...styles,

        // background:props?.type === props?.selectedType
        // ? primary.main
        // : "grey",
      }}
    >
      <LazyLoadImage
        src={props.thumbnail}
        alt={`${props?.type} Vehicle`}
        style={{
          ...styles,
          padding: "0px",
          borderRadius: "50%",
          // border: "1px solid red",
          // background:
          //   props?.type === props?.selectedType ? primary?.main : "#E0E0E0",
          border:
            props?.type === props?.selectedType
              ? `1px solid ${"#D6EBF5"}`
              : `1px solid ${"#EFEFEF"}`,
          width: "75px",
          height: "75px",
        }}
      />
      {/* <Image
        width="75"
        height="75"
        src={props.thumbnail}
        alt={`${props?.type} Vehicle`}
        style={{
          ...styles,
          padding: "4px",
          borderRadius: "50%",
          background:
            props?.type === props?.selectedType ? "#3399CC" : "#E0E0E0",
          border:
            props?.type === props?.selectedType
              ? `4px solid ${"#D6EBF5"}`
              : `4px solid ${"#EFEFEF"}`,
        }}
      /> */}
    </Box>
  );
};

const MemoizedVehicleBadges = React.memo(VehicleBadges);

export default MemoizedVehicleBadges;
