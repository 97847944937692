import { Box, styled, Tab } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import * as React from "react";

import MenuIcon from "@mui/icons-material/Menu";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PaymentsRoundedIcon from "@mui/icons-material/PaymentsRounded";
import StorefrontIcon from "@mui/icons-material/Storefront";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { primary } from "../../../constants/theme";
import { Context } from "../../../context/context";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import { myScroll } from "../../../utils/myScroll";

const CustomTabHeader = styled(Box)(({ theme }) => ({
  //margin:"10px",
  display: "flex",
  justifyContent: "center",
  boxShadow: "0px 4px 100px 5px rgba(0, 0, 0, 0.1)",
  //marginTop:"20px",
  position: "fixed",
  background: "white",
  width: "100%",
  paddingBottom: "10px",
  zIndex: 999,
  ".tabInfo": {
    textTransform: "none",
    fontSize: 15,

    // width:"25%",
    padding: "8px",
  },

  ".MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: primary?.main,
    maxWidth: 35,
    marginLeft: "10px",

    padding: "1px",
    //marginLeft: "30px",
  },

  "@media (max-width:390px)": {
    fontSize: 12,
  },

  // ".stage": {
  //   //borderBottom: '3px solid #444',
  //   //display: 'flex',
  //  // height: '330px',
  //   //width: '100%',
  // },
  // ".box": {
  //   alignSelf: 'flex-end',
  //   animationDuration: '2s',
  //   animationIterationCount: 'infinite',

  //   //height: '200px',
  //   margin: '0 auto 0 auto',
  //   transformOrigin: 'bottom',
  //   //width: '200px',
  // },
  // ".bounce-4": {
  //   animationName: 'bounce-4',
  //   animationTimingFunction: 'ease',
  // },
  // '@keyframes  bounce-4': {
  //   '0%': { transform: 'scale(1,1) translateY(0)' },
  //   '10%': { transform: 'scale(1.1,.9) translateY(0)' },
  //   '30%': { transform: 'scale(.9,1.1) translateY(-100px)' },
  //   '50%': { transform: 'scale(1,1) translateY(0)' },
  //   '100%': { transform: 'scale(1,1) translateY(0)' },
  // },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    ".tabInfo": {
      minWidth: "70px"
    }
  },
}));

export default function TabHeader() {
  const [value, setValue] = React.useState(0);

  const { showHeader } = useContext(Context);

  const state = useContext(Context);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (
      state?.testDriveDetails?.location === "Store" ||
      state?.testDriveDetails?.location === "Home"
    ) {
      myScroll(
        0,
        document.getElementById("test-drive").offsetTop - 100,
        "smooth"
      );
    }
    // eslint-disable-next-line
  }, [state?.testDriveDetails?.location]);

  return (
    <CustomTabHeader>
      <Tabs value={value ?? 0} onChange={handleChange} centered>
        <Tab
          icon={
            <LazyLoadImage
              visibleByDefault={true}
              fetchpriority={"high"}
              //src={value === 0 ? "/moon.svg" : "/disablemoon.svg"}
              src={value === 0 ? "/logo.svg" : "/disable-logo.svg"}
              style={{ maxWidth: "30px" }}
              alt="electric-gaadi"
            />

            //        <Box className="stage">
            //     <Box className="box bounce-4"><LazyLoadImage
            //               visibleByDefault={true}
            //               fetchpriority={"high"}
            //               src={value === 0 ? "/moon.webp" : "/disable-logo.svg"}
            //               style={{width:"40px"}}
            //               alt="electric-gaadi"
            //             /></Box>
            // </Box>
          }
          label="Electric Vehicle"
          className="tabInfo"
          onClick={() => {
            sendAnalytics("v2_header_electric_gadi", {}, "en");
            myScroll(0, 0, "smooth");
          }}
        />
        <Tab
          icon={value === 1 ? <StorefrontRoundedIcon /> : <StorefrontIcon />}
          label="Showroom"
          className="tabInfo"
          onClick={() => {
            sendAnalytics("v2_header_showroom", {}, "en");
            myScroll(
              0,
              document.getElementById("book-ticket").offsetTop - 100,
              "smooth"
            );
          }}
        />
        <Tab
          icon={
            value === 2 ? <PaymentsRoundedIcon /> : <PaymentsOutlinedIcon />
          }
          label="Loan"
          className="tabInfo"
          onClick={() => {
            sendAnalytics("v2_header_loan", {}, "en");
            myScroll(
              0,
              document.getElementById("loan-disbursed").offsetTop - 100,
              "smooth"
            );
          }}
        />
        <Tab
          icon={<MenuIcon />}
          label="More"
          className="tabInfo"
          disableRipple={true}
          onClick={() => {
            sendAnalytics("v2_ham_menu_clicked", {}, "en");

            showHeader();
          }}
        />
      </Tabs>
    </CustomTabHeader>
  );
}
