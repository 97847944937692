import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, styled } from "@mui/material";
import { useRouter } from "next/router";
import * as React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MemoizedButton } from "../../../SDK/button";
import { base64Image } from "../../../constants/base64Image";
import { primary } from "../../../constants/theme";
import { imagesWEBP } from "../../../constants/webpImages";
import { Context } from "../../../context/context";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import BookEVConsultationForm from "../BookEVconsult/bookEVConsultation";
import Image from "next/image"
const CustomHeaderBanner = styled(Box)(({ theme }) => ({
  // display: "flex",
  justifyContent: "center",
  margin: "10px",
  marginTop: "90px",
  ".Whatsapp-main": {
    bottom: "70px",
    marginBottom: "16px",
    position: "fixed",
    right: "20px",
    textAlign: "end",
    zIndex: "999",
  },
  ".whatsapp": {
    background: "#35BF68",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    padding: "9px 10px 8px 10px",
    marginLeft: "auto",
    border: "5px solid #ffffff",
  },
  ".whatsapp svg": {
    color: primary.white,
    width: "30px",
    height: "30px",
  },

  "@media (max-width:390px)": {
    fontSize: 12,
  },

  [theme.breakpoints.down("md")]: {},
}));

export default function HeaderBanner({ imageUrl }) {
  const { showCarouselPopup, showConnectDrawer, showLoanPopup } =
    React.useContext(Context);

  const navigate = useRouter();

  return (
    <CustomHeaderBanner>
      <Image
        alt="Commercial Electric Vehicles"
        style={{ width: "100%", minHeight: "280px", borderRadius: "8px" }}
        src={imageUrl ? imageUrl : base64Image?.homeBanner}
        visibleByDefault={true}
        // fetchpriority={"high"}
        priority={true}
        width={"1080"}
        height={"355"}
        onClick={() => {
          let payload = {
            type: "v2_home_banner",
            trigger: "whatsapp",
          };
          sendAnalytics("v2_lead_form_opened", payload, "en");
          showConnectDrawer("Banner_Lead_Form");
          // showCarouselPopup("v2_home_banner");
        }}
      />

      <Box
        className="Whatsapp-main"
        sx={{ maxWidth: "100px", width: "100%", margin: "auto" }}
        onClick={() => {
          sendAnalytics(
            "v2_whatsapp_floating_button",
            { type: "floater_whatsapp_icon", trigger: "whatsapp" },
            "en"
          );
          showConnectDrawer("floater_whatsapp_icon");
        }}
      >
        <Box className="whatsapp">
          <WhatsAppIcon className="whatsapp-logo" />
        </Box>
      </Box>

      <BookEVConsultationForm />

      {/* <LazyLoadImage
        alt="Greaves Launch"
        style={{
          width: "100%",
          minHeight: "280px",
          borderRadius: "8px",
          marginTop: "20px",
        }}
        src={imagesWEBP?.greavesLaunch}
        visibleByDefault={true}
        fetchpriority={"high"}
        onClick={() => {
          let payload = {
            type: "v2_home_GreavesLaunch",
            trigger: "greavesLaunch",
          };
          sendAnalytics("v2_lead_form_opened", payload, "en");
          showConnectDrawer("Banner_Lead_Form");
          // showCarouselPopup("v2_home_banner");
        }}
      />

      <MemoizedButton
        className="testDriveCta"
        idlocal={`testDriveCta`}
        content={`Get On Road Price`}
        style={{
          width: "100%",
          borderRadius: "8px",
          boxShadow: "none",
          marginTop: "8px",
        }}
        handleClick={() => {
          let payload = {
            vehicle_id: 13,
            vehicle_name: "Greaves Eltra",
          };
          sendAnalytics("v2_view_vehicle", payload, "en");

          const loanPayload = {
            brand: "Greaves",
            formTrigger: "v2_view_vehicle_greaves",
            vehicle_id: "Eltra",
            CTAText: "Check Vehicle",
            headingText: "Enter Your Mobile Number",
          };

          if (localStorage?.getItem("phoneInfo") === null) {
            showLoanPopup(loanPayload);
          } else {
            navigate.push(`/electric-commercial-vehicles/Greaves/Eltra`);
          }

          // navigate.push(`/vehicles/${vehicleData?.brand}`);
          // showCarouselPopup();
        }}
      /> */}

      {/* V2_State_Test */}

      {/* <EvConsultationForm /> */}
    </CustomHeaderBanner>
  );
}
