export const happyCustomerInfo = [
  {
    ID: 1,
    name: "Naveen",
    business: "Water Can Distributor",
    location: "Bengaluru",
    vehicle: "Euler",
    videoUrl: "https://www.youtube.com/embed/mKR2OzjjDMg",
    thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/happyCustomer/cust_1.webp`,
    rate: "0.5",
    earning: "24,000",
    type: "qr-1",
  },
  {
    ID: 2,
    name: "Ramesh",
    business: "Restaurant Chain",
    location: "Bengaluru",
    vehicle: "Mahindra Treo Zor",
    videoUrl: "https://www.youtube.com/embed/EJURYcfTZfI",
    thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/happyCustomer/cust_2.webp`,
    rate: "0.5",
    earning: "24,600",
    type: "qr-2",
  },
  {
    ID: 3,
    name: "Vasanth",
    business: "Courier Company",
    location: "Bengaluru",
    vehicle: "E-Trio Touro Max",
    videoUrl: "https://www.youtube.com/embed/3auVEm3e9Xk",
    thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/happyCustomer/cust_6.webp`,
    rate: "0.5",
    earning: "23,000",
    type: "qr-3",
  },
  {
    ID: 4,
    name: "Sameer",
    business: "Delivery Business",
    location: "Bengaluru",
    vehicle: "Piaggio Fx Max",
    videoUrl: "https://www.youtube.com/embed/0QG4m2TAByw",
    thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/happyCustomer/cust_5.webp`,
    rate: "0.5",
    earning: "22,500",
    type: "qr-4",
  },
  {
    ID: 5,
    name: "Girish",
    business: "Delivery Business",
    location: "Bengaluru",
    vehicle: "Piaggio Fx Max",
    videoUrl: "https://www.youtube.com/embed/RwAOk6mWpr0",
    thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/happyCustomer/cust_7.webp`,
    rate: "0.5",
    earning: "26,000",
    type: "qr-5",
  },
  {
    ID: 6,
    name: "Kamble",
    business: "Food Supply",
    location: "Bengaluru",
    vehicle: "E-Trio Touro Max",
    videoUrl: "https://www.youtube.com/embed/9IMInydii-Q",
    thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/happyCustomer/cust_4.webp`,
    rate: "0.5",
    earning: "30,000",
    type: "qr-6",
  },
];
