import React, { useContext } from "react";
import { sendAnalytics } from "../../utils/analyticsCommon";
import { Context } from "../../context/context";
import { useRouter } from "next/router";
import styles from "./CheckEMICard.module.css";
import { useMobile } from "../../utils/findViewport";
import Image from "next/image";

const CheckEMICard = ({ cardDetails = [], isHindi = false }) => {
  const { showLoanPopup } = useContext(Context);
  const navigate = useRouter();
  const isMobile = useMobile();
  const isCargoLogoVisible = false;
  const {
    cardHeader,
    cardSubHeader,
    downPayment: { value = "", title = "" } = {},
    interestRate: {
      value: interestRateValue = "",
      title: interestRateTitle = "",
      subTitle: interestRateSubTitle = "",
    } = {},
    emi: { value: emiValue = "", title: emiTitle = "" } = {},
    checkEmiButtonText = "",
  } = cardDetails;

  const handleEmiButtonClick = () => {
    const { skipFinancePage = false } = cardDetails;
    const headerText = isHindi
      ? "अपना मोबाइल संख्या दर्ज करे"
      : "Enter Your Mobile Number";
    const CTAText = isHindi ? "EMI देखें" : "Check EMI";
    let payload = {
      type: "v2_loan_card",
    };
    sendAnalytics("v2_loan_card_lead_form_opened", payload, "en");
    const loanPayload = {
      brand: "",
      formTrigger: "v2_vehicle_Loan",
      CTAText: CTAText,
      headingText: headerText,
      skipFinancePage: skipFinancePage,
    };
    if (skipFinancePage) {
      showLoanPopup(loanPayload);
    } else {
      if (localStorage?.getItem("phoneInfo") === null) {
        showLoanPopup(loanPayload);
      } else {
        navigate.push(`/finance`);
      }
    }
  };

  return (
    <div className={styles?.checkemiMainContainer}>
      <div className={styles?.topContainer}>
        <div className={styles?.header}>{cardHeader}</div>
        <div className={styles?.imageContainer}>
          <div className={styles?.tcTextContainer}>{cardSubHeader}</div>
          {isCargoLogoVisible && (
            <Image
              src={imagesWEBP?.vehiclePageLoanBannerMobileimage}
              alt={"cargo-vehicleImage"}
              style={{
                width: isMobile ? "70px" : "140px",
                height: isMobile ? "70px" : "140px",
              }}
            />
          )}
        </div>
      </div>
      <div className={styles?.bottomContainer}>
        <div className={styles?.interestRateContainer}>
          <div className={styles?.interestRateSubContainer}>
            <div className={styles?.value}>{value}</div>
            <div className={styles?.title}>{title}</div>
          </div>
          <div className={styles?.interestRateSubContainer}>
            <div className={styles?.value}>{interestRateValue}</div>
            <div className={styles?.title}>
              {interestRateTitle}
              <br />
              {interestRateSubTitle && (
                <span className={styles?.Subtitle}>{interestRateSubTitle}</span>
              )}
            </div>
          </div>
          <div className={styles?.interestRateSubContainer}>
            <div className={styles?.value}>{emiValue}</div>
            <div className={styles?.title}>{emiTitle}</div>
          </div>
        </div>
        <div>
          <button className={styles?.buttonEmi} onClick={handleEmiButtonClick}>
            {checkEmiButtonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckEMICard;
