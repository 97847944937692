import { Box, Typography } from "@material-ui/core";
import { Stack, styled } from "@mui/material";
import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";

import { primary } from "../../constants/theme";
import { MemoizedButton } from "../../SDK/button";
import CountDownTimer from "../../SDK/CountDownTimer/CountDownTimer";
import { sendAnalytics } from "../../utils/analyticsCommon";

const CustomVisitStore = styled(Box)(({ theme }) => ({
  height: "300px",

  padding: "40px",
  background: `linear-gradient(69.72deg, rgba(232, 52, 94, 0.76) 17.38%, rgba(73, 69, 153, 0.76) 98.94%)`,

  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  // width: "100%",
  margin: "40px 20px",
  borderRadius: "8px",

  ".turno-electric": {
    fontSize: "28px",
    lineHeight: "42px",
    fontWeight: "400",
    color: primary.white,
    ".electric": {
      color: primary.main,
    },
  },
  ".heading-about-turno": {
    fontSize: "48px",
    lineHeight: "64px",
    fontWeight: "700",
    color: primary.white,
    span: {
      color: primary.electricGreen,
    },
  },

  ".boxLayout": {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },

  ".turno-electric-info": {
    fontSize: "20px",
    paddingTop: "20px",
    paddingBottom: "10px",
    fontWeight: "400",
    color: primary.white,
    ".electric": {
      color: primary.main,
    },
  },

  ".visitStoreCta": {
    borderRadius: "8px",
    background: primary.white,
    color: primary.mainText,
    padding: "8px",
    width: "300px",
    margin: "30px",
    marginTop: "8px",
    "&:hover": {
      color: primary.main,
      background: primary.white,
    },
  },

  [theme.breakpoints.down("md")]: {
    height: "220px",
    padding: "20px",
    marginTop: "40px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    ".visitStoreCta": {
      width: "200px",
      // display:"none"
    },

    ".turno-electric": {
      //display:"none",
      fontSize: "18px",
      lineHeight: "27px",
      //marginBottom: "12px",

      alignItems: "center",
    },
    ".heading-about-turno": {
      //display:"none",
      fontSize: "25px",
      lineHeight: "35px",
    },

    ".turno-electric-info": {
      //display:"none",
      fontSize: "14px",
      paddingTop: "8px",
      // paddingBottom: "8px",

      fontWeight: "400",
      color: primary.white,

      ".electric": {
        // display:"none",
        color: primary.main,
      },
    },
  },
}));

const VisitStore = () => {
  const navigate = useRouter();
  const targetRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        sendAnalytics("v2_scroll_store_card", {}, "en");
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef]);
  return (
    <CustomVisitStore>
      <Box className="boxLayout" id="book-ticket" ref={targetRef}>
        <Typography variant="h2" className="heading-about-turno">
          Turno Multibrand Store
        </Typography>

        {/* <Typography variant="h2" className="turno-electric">
          OPENING IN BANGALORE
        </Typography> */}
        <Stack className="turno-electric">
          <CountDownTimer />
        </Stack>
        <Typography variant="h5" className="turno-electric-info">
          Be one of the first visitors and stand a chance to win a cashback of
          up to ₹ 5, 000/- on booking any Vehicle.
        </Typography>

        <Stack
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <MemoizedButton
            className="visitStoreCta"
            idlocal={`button123`}
            content={`Visit Store`}
            handleClick={() => {
              let payload = {
                button_name: "visit_store",
              };
              sendAnalytics("visit_store_clicked", payload, "en");
              navigate.push("/brand-store");
            }}
          />
        </Stack>
      </Box>
    </CustomVisitStore>
  );
};
export default VisitStore;

export const MemoizedVisitStore = React.memo(VisitStore);
