import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { mapStates } from "../../../../constants/mapRegions";

import { Context } from "../../../../context/context";
import LeadService from "../../../../services/leadService";
import { validLeadConvert } from "../../../../services/leadValidation";
import sessionTracking from "../../../../services/sessionTracking";
import { userQlFlow } from "../../../../services/userQLFlow";
import { sendAnalytics } from "../../../../utils/analyticsCommon";

const useTestDrive = () => {
  const state = useContext(Context);
  const { showSnackbar, updateUserQlData, showReviewStoryDrawer } =
    useContext(Context);
  const [loading, setLoading] = useState(false);
  const navigate = useRouter();
  //   const [vehicleDetails, setVehicleDetails] = useState({});
  //   let cacheInfo = getCookie("user_verified")
  //     ? JSON.parse(getCookie("user_verified"))
  //     : "";
  const leadIntent = "HIGH_INTENT";

  const form = useFormik({
    validateOnChange: true,
    initialValues: {
      mobile: "",
      checkbox: true,
    },

    validate: (values) => {
      const errors = { ...values?.errors };

      if (!values?.mobile?.toString()) {
        errors.mobile = "Mobile Number is Mandatory";
      }
      if (values?.mobile?.toString()?.length !== 10) {
        errors.mobile = "Enter a valid 10 digit Mobile Number";
      }
      if (values?.mobile && values?.mobile[0] < 5) {
        errors.mobile = "Enter Valid Mobile Number";
      }

      const ipInformation = localStorage.getItem("geoLocation")
        ? JSON.parse(localStorage.getItem("geoLocation"))
        : {};

      if (values?.mobile?.toString()?.length === 10) {
        let payload = {
          phoneNumber: values?.mobile,
          connectType: "Store form",
          type: "v2_test_drive",
          cityInfo: ipInformation?.city,
          geoInfo: ipInformation,
        };
        sendAnalytics("v2_lead_form_entered_mobile", payload, "en");

        const leadPayload = {
          name: "",
          phone: values?.mobile,
          state: mapStates[ipInformation?.countryRegion] ?? "Others",
          leadIntent: leadIntent,
          subSource: "Store form",
        };

        // param: leadpayload, analyticsPayload
        validLeadConvert(leadPayload, payload);
      }

      return errors;
    },

    onSubmit: (values) => {
      const analyticsPayload = {
        phone_number: values?.mobile,
        name: "",
        state: "",
        button_trigger_location: state?.carouselDrawerTrigger,
        type: "v2_test_drive",
      };

      sendAnalytics("reserve_vehicle_button_clicked", analyticsPayload, "en");
      const payload = {
        name: "",
        phone: values?.mobile,
        pan: "",
        pincode: "",
        dob: "",
        state: "",
        optedExchange: "",
        // vehicleSelected: brand,
        language: "",
        refNumber: "",
        leadIntent: leadIntent,
        subSource: "Store form",
        verified: state?.leadVerifiedStatus,
      };

      setLoading(true);

      LeadService(payload)
        .then((response) => {
          const sessionInfo = {
            leadUuid: response?.data?.uuid,
            newSession: true,
            formSubmitted: true,
            leadIntent: "LOW_INTENT",
          };

          sessionTracking(sessionInfo);
          showSnackbar("We will get back to you soon!!", "success");
          setLoading(false);

          //navigate.push("/test-drive/vehicles");

          // Enable QL Lead flow
          userQlFlow(state, updateUserQlData, showReviewStoryDrawer);
        })
        .catch((error) => {
          showSnackbar(
            "Oops, something went wrong. Please try again later.",
            "error"
          );
        });
    },
  });
  return {
    form,
    loading,
  };
};

export default useTestDrive;
