import { Box, styled, Typography } from "@mui/material";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "swiper/css";
import "swiper/css/pagination";
import { Context } from "../../../context/context";

import { primary } from "../../../constants/theme";
import { imagesWEBP } from "../../../constants/webpImages";
import { MemoizedButton } from "../../../SDK/button";
import { sendAnalytics } from "../../../utils/analyticsCommon";
const CustomModelCard = styled(Box)(({ theme }) => ({
  //   background: "#FFF9FA",

  ".vehicle-box": {
    margin: "8px",
    background: primary?.white,
    // padding: "10px",
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
  },

  ".vehicle-image-box": {
    // width: "100%",
    minHeight: "110px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0px 5px",
  },
  ".vehicle-image": {
    maxHeight: "180px",
    //width: "100%",
  },
  ".book-test-drive-button": {
    borderRadius: "8px",
    boxShadow: "none",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
  },

  ".name-text": {
    fontSize: "12px",
    padding: "5px 10px 0px",
    fontWeight: 700,
  },
  ".desc-text": {
    fontSize: "12px",
    //minHeight: "40px",
    padding: "5px 10px 5px",
  },
  //   ".form-box": {
  //     width: "100%",
  //   },
  ".check-vehicle-button": {
    background: "#FEEBEF !important",
    color: "#E8345E !important",
    fontSize: "12px !important",
    borderRadius: "5px !important",
    padding: "10px",
    width: "100%",
    boxShadow: "none !important",
    border: "none !important",
  },

  [theme.breakpoints.down("md")]: {
    ".vehicle-image": {
      height: "100px",
      //width: "100%",
    },
  },
}));

const ModelCard = ({ data, hideButton }) => {
  //   cons;
  //const navigate = useRouter();

  const { showLoanPopup } = useContext(Context);
  const navigate = useRouter();
  const brandName = data?.brand;

  const name = brandName.charAt(0).toUpperCase() + brandName.slice(1);

  return (
    <CustomModelCard>
      <Box
        className="vehicle-box"
        onClick={() => {
          let payload = {
            vehicle_id: data?.id,
            vehicle_name: data?.name,
          };
          sendAnalytics("v2_latest_model_check_vehicle", payload, "en");

          const loanPayload = {
            brand: data?.brand,
            formTrigger: "v2_view_vehicle_latest_model",
            vehicle_id: data?.vehicle_path_id,
            headingText: "Enter Your Mobile Number",
            CTAText: "Check EMI",
          };

          if (localStorage?.getItem("phoneInfo") === null) {
            showLoanPopup(loanPayload);
          } else {
            navigate.push(
              `/electric-commercial-vehicles/${data?.brand}/${data?.vehicle_path_id}`
            );
          }

          //navigate.push(`/vehicles/${data?.brand}`);
        }}
      >
        <Box className="vehicle-image-box">
          <LazyLoadImage className="vehicle-image" src={data?.latestModel} alt="latest model"/>
        </Box>
        <Box
          style={{
            display: "flex",
            width: "100% !important",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography className="name-text">{name}</Typography>
          {data?.emiPrice === "11,000" && (
            <LazyLoadImage
              src={imagesWEBP?.lowestEmiTag}
              style={{
                height: "20px",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
              }}
              alt="lowest emi tag"
            />
          )}

          {data?.vehicle_name === "E-Xtra FX" && (
            <LazyLoadImage
              src={imagesWEBP?.topSellingTag}
              style={{
                height: "20px",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
              }}
              alt="top selling tag"
            />
          )}
        </Box>
        <Box>
          <Typography style={{ fontSize: "10px", paddingLeft: "10px" }}>
            {data?.vehicle_name}
          </Typography>
          <Typography
            style={{
              fontSize: "10px",
              paddingLeft: "10px",
              fontWeight: "800",
              paddingBottom: "8px",
            }}
          >
            Starting EMI ₹{data?.emiPrice}/- p.m.
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          display: hideButton ? "none" : "flex",
          justifyContent: "center",
          margin: "10px",
        }}
      >
        <MemoizedButton
          className="check-vehicle-button"
          content={`Check Vehicle`}
          handleClick={() => {
            let payload = {
              vehicle_id: data?.id,
              vehicle_name: data?.name,
            };
            sendAnalytics("v2_latest_model_check_vehicle", payload, "en");

            const loanPayload = {
              brand: data?.brand,
              formTrigger: "v2_view_vehicle_latest_model",
              vehicle_id: data?.vehicle_path_id,
              headingText: "Enter Your Mobile Number",
              CTAText: "Check EMI",
            };
            if (localStorage?.getItem("phoneInfo") === null) {
              showLoanPopup(loanPayload);
            } else {
              navigate.push(
                `/electric-commercial-vehicles/${data?.brand}/${data?.vehicle_path_id}`
              );
            }
            //navigate.push(`/vehicles/${data?.brand}`);
          }}
        />
      </Box>
    </CustomModelCard>
  );
};

export default ModelCard;
