import {
  Box,
  Checkbox,
  InputAdornment,
  styled,
  Typography,
} from "@mui/material";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { imagesList } from "../../../constants";
import { happyCustomerInfo } from "../../../constants/happyCustomerData";
import { primary } from "../../../constants/theme";
import { MemoizedButton } from "../../../SDK/button";
import { MemoizedPhoneField } from "../../../SDK/input/phoneInput";
import { checkError } from "../../../SDK/utils/validationHelpers";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import MemoizedVehicleBadges from "../HomePageVehicleThumbnail/vehicleBatch";

const CustomHappyCustomers = styled(Box)(({ theme }) => ({
  //   background: "#FFF9FA",
  ".main-box": {
    margin: "10px",
  },
  ".head-text": {
    padding: "0px 10px",
    fontWeight: 600,
    fontSize: "22px",
    marginTop: "30px",
    marginBottom: "20px",
    paddingLeft: "16px",
  },
  ".image-box": {
    width: "100%",
    padding: "20px 0px",
  },
  ".test-drive-image": {
    width: "100%",
  },

  ".cutomerList": {
    overflowX: "auto",
    display: "flex",
    overflowY: "hidden",
    flexDirection: "row",
    whiteSpace: "nowrap",
    margin: "10px 4px 10px 4px",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const HappyCustomers = ({
  title = "Customers in 10+ Cities Bought from Us",
  isHomePage = false
}) => {
  const [type, setType] = useState(happyCustomerInfo[0]?.type);
  // const [customerData, setVehicleData] = useState(
  //   happyCustomerInfo[customerId ?? 0]
  // );

  const navigate = useRouter();

  const handleSelection = (res) => {
    // setVehicleData(res);
    setType(res?.type);
  };

  const targetRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        sendAnalytics("v2_scroll_happy_customers", {}, "en");
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef]);
  return (
    <CustomHappyCustomers>
      <Box className="main-box" id="happy-customers" ref={targetRef}>
        <Box>
          <Typography className="head-text">
            {navigate.pathname === "/" && (
              <span style={{ color: primary?.main }}>4000+ </span>
            )}
            {title}
          </Typography>
        </Box>

        {/* <Box className="image-box">
          <LazyLoadImage
            className="test-drive-image"
            src={imagesList.productRating1}
          />
        </Box> */}
        <Box>
          <Box className="cutomerList">
            {happyCustomerInfo?.map((res, key) => {
              return (
                <Box
                  textAlign={"center"}
                  key={key}
                  onClick={() => {
                    handleSelection(res);
                    let payload = {
                      customer_id: res?.ID,
                      customer_name: res?.name,
                    };
                    sendAnalytics("v2_happy_customer_clicked", payload, "en");
                    navigate.push("/happycustomers/" + res?.ID);
                  }}
                >
                  <MemoizedVehicleBadges
                    selectedType={type}
                    type={res?.type}
                    thumbnail={res?.thumbnail}
                  />
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                  >
                    {res?.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </CustomHappyCustomers>
  );
};

export default HappyCustomers;
