import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Box, Grid, Slider, styled, Typography } from "@mui/material";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { imagesList } from "../../../constants";
import { primary } from "../../../constants/theme";
import { MemoizedButton } from "../../../SDK/button";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import { leadActions } from "../../../utils/leadActions";

const CustomTCO = styled(Box)(({ theme }) => ({
  background: "#FFF7E2",
  ".MuiSlider-markLabel": {
    fontWeight: "600",
  },
  ".head-text": {
    padding: "0px 10px",
    fontWeight: 600,
    fontSize: "27px",
    marginTop: "20px",
    paddingLeft: "16px",
    marginBottom: "0px",
  },
}));
const TCO = () => {
  const navigate = useRouter();
  const [kms, setKms] = useState(60);
  const [years, setYears] = useState(3);
  const yearsList = [
    {
      value: 3,
      label: "3 years",
    },
    {
      value: 4,
      label: "4 years",
    },
    {
      value: 5,
      label: "5 years",
    },
  ];
  const evBasePricesobj = {
    fuelCost: 225 * years * kms,
    maintenance: 2500 * years + 6 * years * (kms - 20),
  };
  const CNGPricesobj = {
    fuelCost: 663 * years * kms,
    maintenance: 16000 * years + 48 * years * (kms - 20),
  };
  const petrolPricesobj = {
    fuelCost: 663 * years * kms,
    maintenance: 16000 * years + 48 * years * (kms - 20),
  };
  const [evPrices, setEVPrices] = useState(evBasePricesobj);
  const [CNGPrices, setCNGPrices] = useState(CNGPricesobj);
  const [petrolPrices, setPetrolPrices] = useState(petrolPricesobj);

  const valueKilometers = (value) => {
    return `${value}°C`;
  };

  const valueYears = (value) => {
    return `${value}°C`;
  };

  function valueLabelFormat(value) {
    return yearsList.findIndex((year) => year.value === value) + 3;
  }
  const handleEVPrices = (years, kms) => {
    let obj = {
      fuelCost: 225 * years * kms,
      maintenance: 2500 * years + 6 * years * (kms - 20),
    };
    setEVPrices(obj);
  };
  const handleCNGPrices = (years, kms) => {
    let obj = {
      fuelCost: 663 * years * kms,
      maintenance: 16000 * years + 48 * years * (kms - 20),
    };
    setCNGPrices(obj);
  };
  const handlePetrolPrices = (years, kms) => {
    let obj = {
      fuelCost: 950 * years * kms,
      maintenance: 16000 * years + 48 * years * (kms - 20),
    };
    setPetrolPrices(obj);
  };
  const targetRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        sendAnalytics("scroll_tco_fold", {}, "en");
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef]);

  return (
    <CustomTCO>
      <Box
        id="tco-box"
        ref={targetRef}
        style={{ padding: "10px", paddingTop: "1px", paddingBottom: "5px" }}
      >
        <Box>
          <Typography className="head-text">Running Cost with Turno</Typography>
        </Box>
        <Box
          style={{
            marginBottom: "20px",
            marginLeft: "10px",
            paddingLeft: "6px",
          }}
        >
          <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
            Run your <span style={{ fontWeight: "600" }}>Electric Vehicle</span>{" "}
            for 4 days at a cost of{" "}
            <span style={{ fontWeight: "600" }}>1 litre petrol</span>
          </Typography>
        </Box>
        <Box style={{ background: "#35BF68", borderRadius: "4px" }}>
          <Box
            style={{
              padding: "20px 20px 0px 20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{ color: "#ffffff", fontSize: "16px", fontWeight: "700" }}
            >
              Cost of Running
            </Typography>
            <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
              ( {years} years )
            </Typography>
          </Box>
          <Box>
            <Box style={{ padding: "10px" }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Box
                    style={{
                      background: primary.darkGreen,
                      borderRadius: "4px",
                      padding: "5px",
                      width: "100%",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item xs={4}>
                        {" "}
                        <LazyLoadImage
                          src={imagesList.emoji1}
                          height="30px"
                          alt="Emoji"
                        />
                        {(kms > 60 || years > 3) && (
                          <LazyLoadImage
                            src={imagesList.emoji2}
                            height="30px"
                            alt="Emoji"
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        style={{
                          display: "block",
                          paddingLeft: "0px !important",
                          color: "#ffffff",
                        }}
                      >
                        <Box>
                          <Typography
                            style={{
                              textAlign: "end",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Turno Electric
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              textAlign: "end",
                              fontSize: "18px",
                              fontWeight: "700",
                            }}
                          >
                            ₹{" "}
                            {(
                              evPrices?.fuelCost + evPrices?.maintenance
                            ).toLocaleString("en-In")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    style={{
                      background: primary.darkGreen,
                      borderRadius: "4px",
                      padding: "5px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item xs={4}>
                        {" "}
                        <LazyLoadImage
                          src={imagesList.emoji3}
                          height="30px"
                          alt="Emoji"
                        />
                        {(kms > 60 || years > 3) && (
                          <LazyLoadImage
                            src={imagesList.emoji6}
                            height="30px"
                            alt="Emoji"
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        style={{
                          display: "block",

                          color: "#ffffff",
                        }}
                      >
                        <Box>
                          <Typography
                            style={{
                              textAlign: "end",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            CNG
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              textAlign: "end",
                              fontSize: "18px",
                              fontWeight: "700",
                            }}
                          >
                            ₹{" "}
                            {(
                              CNGPrices?.fuelCost + CNGPrices?.maintenance
                            ).toLocaleString("en-In")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Box
                style={{
                  background: primary.darkGreen,
                  borderRadius: "4px",
                  padding: "10px 5px",
                  marginTop: "10px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item xs={4}>
                    {" "}
                    <LazyLoadImage
                      alt="Emoji"
                      src={imagesList.emoji5}
                      height="30px"
                    />
                    {(kms > 60 || years > 3) && (
                      <LazyLoadImage
                        alt="Emoji"
                        src={imagesList.emoji4}
                        height="30px"
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: "block",

                      color: "#ffffff",
                    }}
                  >
                    <Box>
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Diesel
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: "block",

                      color: "#ffffff",
                    }}
                  >
                    <Box>
                      <Typography
                        style={{
                          textAlign: "end",
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                      >
                        ₹{" "}
                        {(
                          petrolPrices?.fuelCost + petrolPrices?.maintenance
                        ).toLocaleString("en-In")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box style={{ background: "#ffffff", margin: "20px 0px 10px 0px" }}>
          <Box style={{ margin: "0px 25px" }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ fontSize: "12px", fontWeight: "600" }}>
                Enter Daily kms{" "}
              </Typography>
              <Typography style={{ fontSize: "18px", fontWeight: "800" }}>
                {kms} km
              </Typography>
            </Box>

            <Slider
              aria-label="Kilometers"
              defaultValue={60}
              getAriaValueText={valueKilometers}
              valueLabelDisplay="auto"
              step={20}
              marks
              style={{ height: "8px" }}
              min={20}
              max={160}
              onChange={(value) => {
                leadActions("CLICK_SAVINGS_ESTIMATION");

                let selectedKms = value.target.value;
                let payload = { switched_km: selectedKms, initial_km: kms };

                sendAnalytics("saving_km_input_switched", payload, "en");
                setKms(selectedKms);
                handleEVPrices(years, selectedKms);
                handleCNGPrices(years, selectedKms);
                handlePetrolPrices(years, selectedKms);
              }}
            />
          </Box>
          <Box style={{ margin: "0px 25px" }}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ fontSize: "12px", fontWeight: "600" }}>
                Years{" "}
              </Typography>
            </Box>

            <Slider
              aria-label="Years"
              defaultValue={3}
              getAriaValueText={valueYears}
              valueLabelDisplay="auto"
              marks={yearsList}
              min={2}
              max={5}
              style={{ height: "8px" }}
              step={null}
              onChange={(value) => {
                leadActions("CLICK_SAVINGS_ESTIMATION");
                let selectedYears = value.target.value;
                let payload = {
                  switched_year: selectedYears,
                  initial_year: years,
                };
                sendAnalytics("saving_year_input_switched", payload, "en");
                setYears(selectedYears);
                handleEVPrices(selectedYears, kms);
                handleCNGPrices(selectedYears, kms);
                handlePetrolPrices(selectedYears, kms);
              }}
              valueLabelFormat={valueLabelFormat}
            />
          </Box>
        </Box>
        <Box>
          <Typography
            style={{ textAlign: "center", color: "#666666", fontSize: "12px" }}
          >
            Calculate your savings with Electric Vehicle now
          </Typography>
        </Box>
        <Box>
          <MemoizedButton
            animated={true}
            content={"Compare Ownership Cost"}
            endIcon={<KeyboardDoubleArrowRightIcon />}
            sx={{
              background: "#35BF68",
              width: "100%",
              background: primary.main,
              borderRadius: "4px",
              padding: "12px 8px",
              fontSize: "16px",
              color: "white",
              "&:hover": {
                background: primary.main,
                color: "white",
              },
            }}
            handleClick={() => {
              leadActions("CLICK_SAVINGS_ESTIMATION");
              navigate.push("/total-cost-of-ownership");
              sendAnalytics("tco_more_detail_button_clicked", {}, "en");
            }}
          />
        </Box>
      </Box>
    </CustomTCO>
  );
};

export default TCO;
