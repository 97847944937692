import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { primary } from "../../../constants/theme";
import { Context } from "../../../context/context";
import { MemoizedButton } from "../../../SDK/button";
import { sendAnalytics } from "../../../utils/analyticsCommon";
// import { primary } from "../../constants/theme";
// import { Context } from "../../context/context";
// import { MemoizedButton } from "../../SDK/button";
// import { myScroll } from "../../utils/myScroll";

const CustomTurnoListCard = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  maxHeight: "600px",
  maxWidth: "400px",
  padding: "10px",
  flexDirection: "column",
  background:
    "linear-gradient(180deg, rgba(242, 242, 242, 0.5) 0%, rgba(53, 191, 104, 0.22) 100%)",
  //"linear-gradient(180deg, rgba(53, 191, 104, 0.132) 0%, rgba(255, 255, 255, 0) 100%)",
  borderRadius: "0.5em",

  ".grid-item-style": {
    display: "flex",
    justifyContent: "center",
    margin: "5px",
  },

  ".grid-container-style": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "8px",
    marginBottom: "8px",

    // margin:'20px'
  },

  ".vehicleName": {
    fontSize: "14px",
    fontWeight: "600",
    margin: "8px",
  },

  ".vehicleDetails": {
    background: primary.white,
    borderRadius: "8px",
    padding: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    minWidth: "100%",
  },

  ".vehicleDetailsHeader": {
    fontSize: "12px",
    color: primary.greyText,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  ".vehicleDetailsdata": {
    fontSize: "14px",
    fontWeight: 600,
  },

  ".subHeader": {
    fontSize: "14px",
    fontWeight: "500",
    color: primary.mainText,
    marginTop: "8px",
  },

  ".subHeaderDetails": {
    fontSize: "24px",
    fontWeight: "600",
    // color: primary.mainGreen,
    marginBottom: "8px",
    color: primary.mainText,
  },
  ".visitStoreCta": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    borderRadius: "8px",
    boxShadow: "none",
    background: primary.white,
    color: primary.main,
    border: `1px solid ${primary.main}`,
  },

  ".testDriveCta": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    borderRadius: "8px",
    boxShadow: "none",
    "&:hover": {
      color: primary.white,
      background: primary.main,
    },
  },

  [theme.breakpoints.down("md")]: {
    // maxWidth: "400px",
    width: "100% !important",
    padding: "12px",

    ".subHeader": {
      fontSize: "12px",
    },
    ".subHeaderDetails": {
      fontSize: "24px",
    },

    ".vehicleName": {
      fontSize: "14px",
    },
    ".testDriveCta": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      borderRadius: "8px",
      boxShadow: "none",
      "&:hover": {
        color: primary.main,
        background: primary.white,
      },
    },
  },
}));

const TurnoListCard = ({ vehicleData }) => {
  const { showCarouselPopup, showVehicleListDrawer, showLoanPopup } =
    useContext(Context);
  const navigate = useRouter();

  const targetRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        sendAnalytics(
          `v2_scroll_mini_card_${vehicleData?.vehicle_path_id}`,
          {},
          "en"
        );
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef, vehicleData?.vehicle_path_id]);

  return (
    <CustomTurnoListCard>
      {/* <FullscreenDrawer vehicleData={vehicleData} /> */}
      <Grid
        Container
        className="grid-container-style"
        ref={targetRef}
        id="vehicle-card"
      >
        <Grid item xs={8} className="grid-item-style">
          <Stack style={{ width: "100%", height: "190px" }}>
            <LazyLoadImage
              src={vehicleData?.homeImage}
              visibleByDefault={true}
              fetchpriority={"high"}
              loading="lazy"
              alg={vehicleData?.name}
              onClick={() => {
                let payload = {
                  vehicle_id: vehicleData?.id,
                  vehicle_name: vehicleData?.name,
                };
                sendAnalytics(
                  "v2_view_vehicle_image_drawer_open_vehicle_image",
                  payload,
                  "en"
                );
                showVehicleListDrawer(vehicleData);
              }}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={4}
          className="grid-item-style"
          flexDirection={"column"}
          style={{ justifyContent: "flex-start", alignItems: "end" }}
        >
          <Stack sx={{ width: "80px" }}>
            <LazyLoadImage src={vehicleData?.vehicle_logo} alt="vehicle logo"/>
          </Stack>
        </Grid>
      </Grid>

      <Grid
        Container
        className="grid-container-style"
        style={{ height: "60px" }}
      >
        <Grid
          item
          xs={6}
          className="grid-item-style"
          style={{ justifyContent: "flex-start" }}
        >
          <Stack sx={{ width: "80px", justifyContent: "flex-start" }}>
            <LazyLoadImage
              src={vehicleData?.galleryIcon}
              onClick={() => {
                let payload = {
                  vehicle_id: vehicleData?.id,
                  vehicle_name: vehicleData?.name,
                };
                sendAnalytics(
                  "v2_view_vehicle_image_drawer_open",
                  payload,
                  "en"
                );
                showVehicleListDrawer(vehicleData);
              }}
              alt="gallery icon"
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          className="grid-item-style"
          flexDirection={"column"}
          style={{ alignItems: "end" }}
        >
          <Stack
            onClick={() => {
              let payload = {
                vehicle_id: vehicleData?.id,
                vehicle_name: vehicleData?.name,
              };
              sendAnalytics("v2_emi_value_vehicle", payload, "en");
              sendAnalytics(
                "v2_loan_card_lead_form_opened",
                { type: "v2_emi_value_vehicle", ...payload },
                "en"
              );

              const loanPayload = {
                brand: vehicleData?.brand,
                formTrigger: "v2_emi_value_vehicle",
                vehicle_id: vehicleData?.vehicle_path_id,
                CTAText: "Check EMI",
                headingText: "Enter Your Mobile Number",
              };
              showLoanPopup(loanPayload);
            }}
          >
            <Typography className="subHeader">EMI Starting From</Typography>
            <Typography className="subHeaderDetails">
              {" "}
              ₹ {vehicleData?.prices["Others"]?.pickup?.emiPrice} p.m.
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Grid
        Container
        className="grid-container-style"
        //  style={{ paddingTop: "0px", height: "60px" }}
        rowSpacing={2}
      >
        <Grid item xs={4} className="grid-item-style">
          <Stack className="vehicleDetails">
            <Typography className="vehicleDetailsHeader">Top Range</Typography>
            <Typography className="vehicleDetailsdata">
              {vehicleData?.range}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4} className="grid-item-style">
          <Stack className="vehicleDetails">
            <Typography className="vehicleDetailsHeader">Top Load</Typography>
            <Typography className="vehicleDetailsdata">
              {vehicleData?.load}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4} className="grid-item-style">
          <Stack className="vehicleDetails">
            <Typography className="vehicleDetailsHeader">Top Speed</Typography>
            <Typography className="vehicleDetailsdata">
              {vehicleData?.topSpeed}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <MemoizedButton
        className="testDriveCta"
        idlocal={`testDriveCta`}
        content={`Get On Road Price`}
        handleClick={() => {
          let payload = {
            vehicle_id: vehicleData?.id,
            vehicle_name: vehicleData?.name,
          };
          sendAnalytics("v2_view_vehicle", payload, "en");
          sendAnalytics(
            "v2_loan_card_lead_form_opened",
            { type: "v2_view_vehicle", ...payload },
            "en"
          );

          const loanPayload = {
            brand: vehicleData?.brand,
            formTrigger: "v2_view_vehicle",
            vehicle_id: vehicleData?.vehicle_path_id,
            CTAText: "Get On Road Price",
            headingText: "Enter Your Mobile Number",
          };

          if (localStorage?.getItem("phoneInfo") === null) {
            showLoanPopup(loanPayload);
          } else {
            navigate.push(
              `/electric-commercial-vehicles/${vehicleData?.brand}/${vehicleData?.vehicle_path_id}`
            );
          }

          // navigate.push(`/vehicles/${vehicleData?.brand}`);
          // showCarouselPopup();
        }}
      />
    </CustomTurnoListCard>
  );
};

export default TurnoListCard;
