import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import StorefrontIcon from "@mui/icons-material/Storefront";
import {
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Link,
  styled,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { imagesList } from "../../../constants";
import { newVehicles, vehicles } from "../../../constants/vehicleData";
import { Context } from "../../../context/context";
import { MemoizedButton } from "../../../SDK/button";
import { MemoizedPhoneField } from "../../../SDK/input/phoneInput";
import { checkError } from "../../../SDK/utils/validationHelpers";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import useTestDrive from "./hooks/useTestDrive";
const CustomTestDrive = styled(Box)(({ theme }) => ({
  background: "#FFF9FA",
  ".main-box": {
    margin: "20px",
    paddingBottom: "30px",
  },
  ".head-text": {
    padding: "10px 10px 0px",
    fontWeight: 600,
    fontSize: "27px",
    paddingLeft: "6px",
  },
  // ".sub-text": {
  //   fontSize: "14px",
  //   padding: "0px 10px",
  // },
  ".sub-text": {
    fontSize: "12px",
    color: "#9C9A9D",
    fontWeight: "600",
    padding: "0px 10px",
    paddingLeft: "6px",
  },
  ".image-box": {
    minHeight: "250px",
    width: "100%",
    padding: "20px 0px",
  },
  ".test-drive-image": {
    width: "100%",
  },
  ".book-test-drive-button": {
    borderRadius: "8px",
    boxShadow: "none",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      background: "#F63B63",
      border: "none",
      color: "white",
      boxShadow: "none",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  ".MuiOutlinedInput-root": {
    paddingRight: "2px !important",
  },
  ".termsconditions": {
    fontSize: "10px",
  },
  ".test-drive-details-box": {
    background: "#ffffff",
    padding: "10px",
    marginTop: "20px",
  },
  //   ".form-box": {
  //     width: "100%",
  //   },
}));

const TestDrive = () => {
  const { form, loading } = useTestDrive();
  const targetRef = useRef(null);
  const state = useContext(Context);

  const vehicleInfo = [...vehicles, ...newVehicles];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        sendAnalytics("v2_scroll_test_drive", {}, "en");
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef]);
  const [selectedVehicleData, setSelectedVehicleData] = useState({});
  const [dateString, setDateString] = useState("");

  // useEffect(() => {
  //   if (
  //     state?.userQlData?.location === "Store" ||
  //     state?.userQlData?.location === "Home"
  //   ) {
  //     vehicleInfo.map((vehicle) => {
  //       if (vehicle?.id === state?.userQlData?.vehicleId) {
  //         setSelectedVehicleData(vehicle);
  //       }
  //     });
  //     let dateTimeString = "";
  //     let x = state?.userQlData?.date?.getDay();
  //     dateTimeString += days[x] + ",";

  //     dateTimeString += " " + state?.userQlData?.date?.getDate();
  //     x = state?.userQlData?.date?.getMonth();
  //     dateTimeString += " " + months[x];
  //     dateTimeString += " " + state?.userQlData?.date?.getFullYear();
  //     setDateString(dateTimeString);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  // const info = vehicleInfo.filter(
  //   (vehicle) => vehicle.id.toString() === state?.testDriveDetails?.vehicleId
  // )[0];

  const vehicleInfoData = vehicleInfo.filter(
    (vehicle) => vehicle.vehicleId === state?.userQlData?.selectedVehicleId
  )[0];

  let dateTimeString = "";
  let x = state?.userQlData?.date?.getDay();
  dateTimeString += days[x] + ",";

  dateTimeString += " " + state?.userQlData?.date?.getDate();
  x = state?.userQlData?.date?.getMonth();
  dateTimeString += " " + months[x];
  dateTimeString += " " + state?.userQlData?.date?.getFullYear();

  return (
    <CustomTestDrive>
      <Box id="test-drive" ref={targetRef}>
        {state?.testDriveDetails?.location === "Store" ||
        state?.testDriveDetails?.location === "Home" ? (
          <Box className="main-box">
            <Box>
              <Typography className="head-text" id="test-drive-info">
                Upcoming Test Drive
              </Typography>
            </Box>
            <Box>
              <Typography className="sub-text" id="test-drive-info">
                Booking Status
              </Typography>
            </Box>

            <Box className="test-drive-details-box">
              <Grid container>
                <Grid item xs={4}>
                  <Box
                    style={{
                      background: "#FEEBEF",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LazyLoadImage
                      src={vehicleInfoData?.latestModel}
                      style={{ width: "100%" }}
                      alt="latest model"
                    />
                  </Box>
                </Grid>
                <Grid item xs={8} style={{ width: "100%" }}>
                  <Box style={{ padding: "10px", width: "100%" }}>
                    <Box>
                      <LazyLoadImage
                        src={vehicleInfoData?.vehicle_logo}
                        style={{
                          height: "30px",
                          // width: "100%",
                        }}
                        alt="vehicle logo"
                      />
                    </Box>
                    <Box>
                      {state?.testDriveDetails?.location === "Store" ? (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                          }}
                        >
                          <StorefrontIcon style={{ marginRight: "5px" }} /> At
                          Store Test Drive
                        </Box>
                      ) : (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                          }}
                        >
                          <HomeOutlinedIcon style={{ marginRight: "5px" }} />{" "}
                          Home Test Drive
                        </Box>
                      )}
                    </Box>
                    <Box
                      style={{
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                      >
                        <CalendarMonthOutlinedIcon
                          style={{ marginRight: "5px" }}
                        />{" "}
                        Date & Time
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        style={{ fontWeight: "700", fontSize: "16px" }}
                      >
                        {" "}
                        {dateTimeString}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Box
                style={{
                  marginTop: "20px",
                  padding: "5px",
                  background: "#EDFFF4",
                  borderRadius: "4px",
                }}
              >
                <Link
                  href="tel:08047482233"
                  hrefLang={"en"}
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                  style={{ width: "100%" }}
                  onClick={() => {
                    // leadActions("CLICK_WHATSAPP_CHAT");
                    // createCookieInHour("lead_trigger", "Phone_Call_Initiated", 10);
                    // sendAnalytics("phone_call_initiated", {}, "en");
                  }}
                >
                  <Grid container columnSpacing={1}>
                    <Grid item xs={2}>
                      {" "}
                      <LazyLoadImage
                        src={imagesList?.turnoCallAgent}
                        style={{ width: "100%" }}
                        alt="turno call agent"
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Box>
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontWeight: "700",
                            color: "#000000",
                          }}
                        >
                          Ramesh Kumar
                        </Typography>
                        <Typography
                          style={{ fontSize: "12px", color: "#000000" }}
                        >
                          Our sales representative will call you soon
                        </Typography>
                      </Box>
                      <Box></Box>
                    </Grid>
                  </Grid>
                </Link>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="main-box">
            <Box>
              <Typography className="head-text">Test Drive</Typography>
            </Box>
            <Box>
              <Typography className="sub-text">BY TURNO</Typography>
            </Box>

            <Box className="image-box">
              <LazyLoadImage
                className="test-drive-image"
                src={imagesList.testDrive}
                alt="test drive image"
              />
            </Box>

            <Box className="form-box">
              <form onSubmit={form.handleSubmit}>
                <Box className="input-box">
                  <MemoizedPhoneField
                    sx={{ minWidth: "100% !important", paddingRight: "0px" }}
                    error={!!checkError("mobile", form)}
                    helperText={form.errors.mobile}
                    className="input-field"
                    name="mobile"
                    placeholder="Your Mobile"
                    value={form.values.mobile}
                    onChange={(e) => {
                      form.handleChange(e);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <MemoizedButton
                            className="book-test-drive-button"
                            type="submit"
                            content={"Book Test Drive"}
                            loading={loading}
                            handleClick={(e) => {
                              form.handleSubmit(e);
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                      checked={form.values.checkbox}
                      onChange={() => {
                        const currCheckboxValue = form.values.checkbox;
                        form.setFieldValue("checkbox", !currCheckboxValue);
                      }}
                      name="checkbox"
                    />

                    <Typography className="termsconditions">
                      By continuing, you’re agreeing to our Customer terms of
                      service, User terms of Service, and Privacy policy.
                    </Typography>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        )}
      </Box>
    </CustomTestDrive>
  );
};

export default TestDrive;
